import Vue from 'vue/dist/vue.esm'

export const start = Vue.observable({
    isFeedOpen: false,
    data: [],
    user: false,
    charity: false,
    get: false,
    clock: false
});

export const current = {
    toggle() {
        start.isFeedOpen = !start.isFeedOpen;
    }
};

export const api_response = {
    feed(x) {
        start.data = x;
    }
};


