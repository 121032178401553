<template>
  <div class = "org_upperbar">
    <div class = "users">
      <a
        ref="user"
        @mouseover="mouseOverUser"
        @mouseleave="mouseLeaveUser"
        @click="userFeed" class = "ss-users">
      </a>
      <!-- <div ref="tool" class="tooltip">User Activity</div> -->
    </div>
    <div class = "clock">
      <a
        ref="clock"
        @mouseover="mouseOverClock"
        @mouseleave="mouseLeaveClock"
        @click="clockFeed"
       class = "ss-clock"></a>
      <!-- <div class="tooltiptext">Charity Activity</div> -->
    </div>
    <div class = "get">
      <a
        ref="get"
        @mouseover="mouseOverGet"
        @mouseleave="mouseLeaveGet"
        @click="getFeed"
       class = "icon-get"></a>
      <!-- <div class="tooltiptext">Charity Activity</div> -->
    </div>
    <div @click="closeFeed" class = "delete">
      <a class = "ss-delete"></a>
    </div>
  </div>
</template>

<script>
import {start, current, api_response} from "./entrypoints/sidebar.js"

export default {
    data() {
        return {
          user: false,
          clock: false,
          get: false
        }
    },
    methods: {
      mouseOverUser() {
        if (this.user === false) {
          event.target.style.color="#5A7877";
        }
        const ttBox = document.createElement("div");
        ttBox.id = "f_tooltip";
        ttBox.style.position = "absolute";
        ttBox.style.zIndex = "2000";
        ttBox.style.top = "60px";
        ttBox.style.left = (window.innerWidth - 339).toString() + "px";
        ttBox.style.opacity = "75%";
        document.body.appendChild(ttBox);
        document.getElementById("f_tooltip").innerHTML = "Volunteers";
      },
      mouseLeaveUser(){
        if (this.user === false) {
          event.target.style.color="#afc6c0";
        }
        const ttBox = document.getElementById("f_tooltip");
        ttBox.animate({opacity: 0}, 50);
        ttBox.parentNode.removeChild(ttBox);
      },
      mouseOverClock() {
        if (this.clock === false) {
          event.target.style.color="#5A7877";
        }
        const ttBox = document.createElement("div");
        ttBox.id = "f_tooltip";
        ttBox.style.position = "absolute";
        ttBox.style.zIndex = "2000";
        ttBox.style.top = "60px";
        ttBox.style.left = (window.innerWidth - 301).toString() + "px";
        ttBox.style.width = "97px";
        ttBox.style.opacity = "75%";
        document.body.appendChild(ttBox);
        document.getElementById("f_tooltip").innerHTML = "Hours";
      },
      mouseLeaveClock(){
        if (this.clock === false) {
          event.target.style.color="#afc6c0";
        }
        const ttBox = document.getElementById("f_tooltip")
        ttBox.animate({opacity: 0}, 50);
        ttBox.parentNode.removeChild(ttBox)
      },
      mouseOverGet() {
        if (this.get === false) {
          event.target.style.color="#5A7877";
        }
        const ttBox = document.createElement("div");
        ttBox.id = "f_tooltip";
        ttBox.style.position = "absolute";
        ttBox.style.zIndex = "2000";
        ttBox.style.top = "60px";
        ttBox.style.left = (window.innerWidth - 257).toString() + "px";
        ttBox.style.width = "97px";
        ttBox.style.opacity = "75%";
        document.body.appendChild(ttBox);
        document.getElementById("f_tooltip").innerHTML = "Purchases";
      },
      mouseLeaveGet(){
        if (this.get === false) {
          event.target.style.color="#afc6c0";
        }
        const ttBox = document.getElementById("f_tooltip")
        ttBox.animate({opacity: 0}, 50);
        ttBox.parentNode.removeChild(ttBox)
      },
      userFeed() {
        this.user = !this.user;
        this.clock = false;
        this.get = false;
        start.clock = false;
        start.get = false;
        this.$refs.clock.style="#afc6c0";
        this.$refs.get.style="#afc6c0";
        if (this.user === true) {
            event.target.style.color="#ee6840";
            start.user = true;
        }
        else {
            event.target.style.color="#afc6c0";
            start.user = false;
        }
      },
      clockFeed() {
        this.clock = !this.clock;
        this.user = false;
        this.get = false;
        start.user = false;
        start.get = false;
        this.$refs.user.style="#afc6c0";
        this.$refs.get.style="#afc6c0";
        if (this.clock === true) {
            event.target.style.color="#ee6840";
            start.clock = true;
        }
        else {
            event.target.style.color="#afc6c0";
            start.clock = false;
        }
      },
      getFeed() {
        this.get = !this.get;
        this.user = false;
        this.clock = false;
        start.clock = false;
        start.user = false;
        this.$refs.user.style="#afc6c0";
        this.$refs.clock.style="#afc6c0";
        if (this.get === true) {
            event.target.style.color="#ee6840";
            start.get = true;
        }
        else {
            event.target.style.color="#afc6c0";
            start.get = false;
        }
      },
      closeFeed() {
          current.toggle()
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
}

</script>

<style lang="scss">
@import "./stylesheets/_sidebar.scss";

</style>
