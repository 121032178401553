<template>
    <!-- <?xml version="1.0" encoding="UTF-8"?> -->
    <svg width="22px" height="22px" viewBox="0 0 20 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
        <title></title>
        <desc>Created with Sketch.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="ActivityIcon" transform="translate(-1.000000, -3.000000)" fill="#AFC6C0">
                <path d="M5.07222053,23.5330536 L20.5598446,41.3438213 C20.9222421,41.7605784 20.8781749,42.392208 20.4614178,42.7546055 C20.2794367,42.9128499 20.0463996,43 19.8052391,43 L8.9863594,43 L8.9863594,43 L3.3607832,24.4798745 C3.20026594,23.9514309 3.49852918,23.3929178 4.02697279,23.2324005 C4.40419261,23.1178182 4.8135297,23.2355592 5.07222053,23.5330536 Z" id="Triangle-Copy" transform="translate(12.000000, 31.500000) scale(1, -1) translate(-12.000000, -31.500000) "></path>
                <path d="M3.07222053,3.5330536 L18.5598446,21.3438213 C18.9222421,21.7605784 18.8781749,22.392208 18.4614178,22.7546055 C18.2794367,22.9128499 18.0463996,23 17.8052391,23 L6.9863594,23 L6.9863594,23 L1.3607832,4.47987453 C1.20026594,3.95143092 1.49852918,3.39291777 2.02697279,3.23240051 C2.40419261,3.1178182 2.8135297,3.23555915 3.07222053,3.5330536 Z" id="Triangle-Copy-2" transform="translate(10.000000, 11.500000) scale(-1, 1) translate(-10.000000, -11.500000) "></path>
            </g>
        </g>
        
    </svg>
</template>

<script>
    export default {
        props: {
            viewBox: { type: String, default: '0 0 20 37'},
            iconName: { type: String, default: ''},
            width: { type: [Number, String], default: 20 },
            height: {type: [Number, String], default: 20 },
            iconColor: {type: String, default: '#AFC6C0'}
        }
    }
</script>

<style>
</style>