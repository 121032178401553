<template>
  <a class = "activity_button">
    <div class="activityIcon" @mouseenter="mouseOver" @mouseleave="mouseLeave" @click="toggler">
      <ActivityImage/>
    </div>
    <!-- <div class="tooltiptext">What's goin' on?</div> -->
  </a>
</template>

<script>
import {start, current, api_response} from "./entrypoints/sidebar.js"
// import activity_image from "./stylesheets/images/_ActivityIcon.svg"
import ActivityImage from "./stylesheets/images/_ActivityIcon.vue"
export default {
    props: {
      varName: Object
    },
    components: {
      ActivityImage
    },
    data() {
      return {
      }
    },
    computed: {
      isFeedActive() {
        return start.isFeedOpen;
      }
    },
    methods: {
      mouseOver() {
        const ttBox = document.createElement("div");
        if(Object.keys(this.$props.varName)[0] === "user") {
          ttBox.id = "a_tooltip";
          ttBox.style.left = (window.innerWidth - 223).toString() + "px";
          // document.getElementById("a_tooltip").innerHTML = "What's going on?";

        }
        else {
          ttBox.id = "o_tooltip";
          ttBox.style.left = (window.innerWidth - 175).toString() + "px";
          // document.getElementById("o_tooltip").innerHTML = "What's going on?";
        }
        // ttBox.style.position = "absolute";
        // ttBox.style.zIndex = "2000";
        // ttBox.style.top = "54px";
        // ttBox.style.opacity = "75%";
        // document.body.appendChild(ttBox);
        // if(Object.keys(this.$props.varName)[0] === "user") {document.getElementById("a_tooltip").innerHTML = "What's going on?";}
        // else {document.getElementById("o_tooltip").innerHTML = "What's going on?";}
      },
      mouseLeave() {
        // console.log("MOUSE GONE")
        if(Object.keys(this.$props.varName)[0] === "user") {
          const ttBox = document.getElementById("a_tooltip");
          ttBox.animate({opacity: 0}, 50);
          ttBox.parentNode.removeChild(ttBox);
        }
        else {
          const ttBox = document.getElementById("o_tooltip");
          ttBox.animate({opacity: 0}, 50);
          ttBox.parentNode.removeChild(ttBox);
        }
        // console.log(ttBox)
      },
      toggler() {
        if (start.isFeedOpen === false) {
          if(Object.keys(this.$props.varName)[0] === "user") {
            var x = 'users/'+ this.$props.varName['user']['slug'] + '/activity_feed.json?entity_class=user';
            // console.log(x);
            this.$http.secured.get(x)
              .finally()
            }

          else {
            var x = 'users/'+ this.$props.varName['organization']['slug'] + '/activity_feed.json?entity_class=organization';
            // console.log(x);
            this.$http.secured.get(x)
              .finally()
            }
        }
          current.toggle();
          //$('#sidebar').toggleClass('show');
      }
    }
}
</script>

<style scoped="scss">
@import "./stylesheets/_sidebar.scss";


</style>
