<template>
  <div class = "activity_sidebar">
      <div class="activity-feed"
      @click ="closeFeed"
      v-if="isFeedOpen">
      </div>
      <transition name="slide">
          <div v-if="isFeedOpen" class="feed">
            <OrgUpperBar />
            <div class="feed_body">
                <div v-if= "this.$props.feed_data.length === 0">
                    <br />
                    <div class = "activity-image">
                        <img class = "loading" v-bind:src="image">
                    </div>
                    Loading Activity...
                </div>
                <div v-else>
                    <div class="no_activity" v-if="Object.keys(this.$props.feed_data).length === 0">
                        <div class = "activity-image">
                            <img class = "loading" v-bind:src="image">
                        </div>
                        <div class="head">No Activity</div>
                        <p>
                            Volunteers, Logged Hours, Ticket Purchases and Donations will show up here.
                        </p>
                    </div>
                    <div v-else-if="isPurchaseOn">
                        <div v-for="activity in getPurchaseFeed" :key="activity">
                            <div v-if="activity['confirmed'] === true && activity['performer_hash'] !== null">
                                <div v-if="activity['target_hash'] !== null ">
                                    <div class = "feed_object">
                                        <div class = "avatars">
                                            <div class = "performer">
                                                <div v-if="activity['target_type'] === 'Opportunity'">
                                                    <a class = "new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">
                                                        <img class="new_img_org" :src= "activity['performer_hash']['avatar']">
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    <div v-if="activity['performer_hash']['name'] === 'Anonymous' || activity['performer_hash']['avatar'] === 'nill'">
                                                        <a class = "new_a" :href="activity['performer_hash']['url']">
                                                            <img class="new_img" :src= "img_anonymous">
                                                        </a>
                                                    </div>
                                                    <div v-else>
                                                        <a class = "new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">
                                                            <img class="new_img" :src= "activity['performer_hash']['avatar']">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="activity['target_type'] !== 'Opportunity'">
                                                <div v-if="activity['target_hash']['avatar'] === 'null'">
                                                    <div class = "target">
                                                        <a class = "new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">
                                                            <img class="new_img_target" v-bind:src= "image2">
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class = "target">
                                                        <a class = "new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">
                                                            <img class="new_img_target" :src= "activity['target_hash']['avatar']">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class = "activity_obj">
                                            <div class = "date">
                                                {{activity["date"]}}
                                            </div>
                                            <div class = "description">
                                                <div class = "performer_test" v-if="activity['target_type'] !== 'Opportunity'">
                                                    <a class="new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">{{activity['performer_hash']['name']}} </a>
                                                </div>
                                                {{activity['action']}}
                                                <a class="new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">{{activity['target_hash']['name']}} </a>
                                                <div style = "display: inline;" v-if = "activity['tribute'] !== false">
                                                    <div style = "display: inline;" v-if="activity['tribute'] !== null">
                                                        as a tribute to {{activity['tribute']}}
                                                    </div>
                                                </div>
                                                <div class = "msg" v-if = "activity['comments'] !== false">
                                                    {{activity['comments']}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="isHourOn">
                        <div v-for="activity in getHourFeed" :key="activity">
                            <div v-if="activity['confirmed'] === true">
                                <div v-if="activity['target_hash'] !== null ">
                                    <div class = "feed_object">
                                        <div class = "avatars">
                                            <div class = "performer">
                                                <div v-if="activity['target_type'] === 'Opportunity'">
                                                    <a class = "new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">
                                                        <img class="new_img_org" :src= "activity['performer_hash']['avatar']">
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    <div v-if="activity['performer_hash']['name'] === 'Anonymous' || activity['performer_hash']['avatar'] === 'nill'">
                                                        <a class = "new_a" :href="activity['performer_hash']['url']">
                                                            <img class="new_img" :src= "img_anonymous">
                                                        </a>
                                                    </div>
                                                    <div v-else>
                                                        <a class = "new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">
                                                            <img class="new_img" :src= "activity['performer_hash']['avatar']">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="activity['target_type'] !== 'Opportunity'">
                                                <div v-if="activity['target_hash']['avatar'] === 'null'">
                                                    <div class = "target">
                                                        <a class = "new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">
                                                            <img class="new_img_target" v-bind:src= "image2">
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class = "target">
                                                        <a class = "new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">
                                                            <img class="new_img_target" :src= "activity['target_hash']['avatar']">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class = "activity_obj">
                                            <div class = "date">
                                                {{activity["date"]}}
                                            </div>
                                            <div class = "description">
                                                <div class = "performer_test" v-if="activity['target_type'] !== 'Opportunity'">
                                                    <a class="new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">{{activity['performer_hash']['name']}} </a>
                                                </div>
                                                {{activity['action']}}
                                                <a class="new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">{{activity['target_hash']['name']}} </a>
                                                <div style = "display: inline;" v-if = "activity['tribute'] !== false">
                                                    <div style = "display: inline;" v-if="activity['tribute'] !== null">
                                                        as a tribute to {{activity['tribute']}}
                                                    </div>
                                                </div>
                                                <div class = "msg" v-if = "activity['comments'] !== false">
                                                    {{activity['comments']}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="isVolunteerOn">
                        <div v-for="activity in getVolunteerFeed" :key="activity">
                            <div v-if="activity['confirmed'] === true">
                                <div v-if="activity['target_hash'] !== null ">
                                    <div class = "feed_object">
                                        <div class = "avatars">
                                            <div class = "performer">
                                                <div v-if="activity['target_type'] === 'Opportunity'">
                                                    <a class = "new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">
                                                        <img class="new_img_org" :src= "activity['performer_hash']['avatar']">
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    <div v-if="activity['performer_hash']['name'] === 'Anonymous' || activity['performer_hash']['avatar'] === 'nill'">
                                                        <a class = "new_a" :href="activity['performer_hash']['url']">
                                                            <img class="new_img" :src= "img_anonymous">
                                                        </a>
                                                    </div>
                                                    <div v-else>
                                                        <a class = "new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">
                                                            <img class="new_img" :src= "activity['performer_hash']['avatar']">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="activity['target_type'] !== 'Opportunity'">
                                                <div v-if="activity['target_hash']['avatar'] === 'null'">
                                                    <div class = "target">
                                                        <a class = "new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">
                                                            <img class="new_img_target" v-bind:src= "image2">
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class = "target">
                                                        <a class = "new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">
                                                            <img class="new_img_target" :src= "activity['target_hash']['avatar']">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class = "activity_obj">
                                            <div class = "date">
                                                {{activity["date"]}}
                                            </div>
                                            <div class = "description">
                                                <div class = "performer_test" v-if="activity['target_type'] !== 'Opportunity'">
                                                    <a class="new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">{{activity['performer_hash']['name']}} </a>
                                                </div>
                                                {{activity['action']}}
                                                <a class="new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">{{activity['target_hash']['name']}} </a>
                                                <div style = "display: inline;" v-if = "activity['tribute'] !== false">
                                                    <div style = "display: inline;" v-if="activity['tribute'] !== null">
                                                        as a tribute to {{activity['tribute']}}
                                                    </div>
                                                </div>
                                                <div class = "msg" v-if = "activity['comments'] !== false">
                                                    {{activity['comments']}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-else>
                        <div v-for="activity in this.$props.feed_data" :key="activity">
                            <div v-if="activity['confirmed'] === true">
                                <div v-if="activity['target_hash'] !== null ">
                                    <div class = "feed_object">
                                        <div class = "avatars">
                                            <div class = "performer">
                                                <div v-if="activity['target_type'] === 'Opportunity'">
                                                    <a class = "new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">
                                                        <img class="new_img_org" :src= "activity['performer_hash']['avatar']">
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    <div v-if="activity['performer_hash']['name'] === 'Anonymous' || activity['performer_hash']['avatar'] === 'nill'">
                                                        <a class = "new_a" :href="activity['performer_hash']['url']">
                                                            <img class="new_img" :src= "img_anonymous">
                                                        </a>
                                                    </div>
                                                    <div v-else>
                                                        <a class = "new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">
                                                            <img class="new_img" :src= "activity['performer_hash']['avatar']">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="activity['target_type'] !== 'Opportunity'">
                                                <div v-if="activity['target_hash']['avatar'] === 'null'">
                                                    <div class = "target">
                                                        <a class = "new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">
                                                            <img class="new_img_target" v-bind:src= "image2">
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class = "target">
                                                        <a class = "new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">
                                                            <img class="new_img_target" :src= "activity['target_hash']['avatar']">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class = "activity_obj">
                                            <div class = "date">
                                                {{activity["date"]}}
                                            </div>
                                            <div class = "description">
                                                <div class = "performer_test" v-if="activity['target_type'] !== 'Opportunity'">
                                                    <a class="new_a" @click="onClick(activity['performer_hash']['url'])" :href="activity['performer_hash']['url']">{{activity['performer_hash']['name']}} </a>
                                                </div>
                                                {{activity['action']}}
                                                <a class="new_a" @click="onClick(activity['target_hash']['url'])" :href="activity['target_hash']['url']">{{activity['target_hash']['name']}} </a>
                                                <div style = "display: inline;" v-if = "activity['tribute'] !== false">
                                                    <div style = "display: inline;" v-if="activity['tribute'] !== null">
                                                        as a tribute to {{activity['tribute']}}
                                                    </div>
                                                </div>
                                                <div class = "msg" v-if = "activity['comments'] !== false">
                                                    {{activity['comments']}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </transition>
  </div>
</template>

<script>
import {start, current, api_response} from "./entrypoints/sidebar.js"
import OrgUpperBar from 'org-upperbar.vue'

export default {
    props: {
        feed_data: Array,
    },
    data() {
        return {
            image: require("./stylesheets/images/_ActivityIcon.svg"),
            image2: require("./stylesheets/images/organization.png"),
            img_anonymous: require("./stylesheets/images/mysterygender.jpg"),
            userFeed: [],
            activityFeed: [],
        }
    },
    components: {
        OrgUpperBar
    },
    methods: {
        onClick(test_url) {
            // console.log("test_url: " + test_url)
            window.open(test_url, "_self")
        },
        closeFeed() {
            current.toggle()
        },
    },
    computed: {
        isFeedOpen() {
            // console.log("SIDEBAR")
            return start.isFeedOpen
        },
        isVolunteerOn() {
            return start.user
        },
        isHourOn() {
            return start.clock
        },
        isPurchaseOn() {
            return start.get;
        },
        getVolunteerFeed() {
            var dummy = []
            for (var activity of this.$props.feed_data) {
                if (activity["target_type"] === "Volunteer") {
                    dummy.push(activity)
                }
            }
            return dummy
        },
        getHourFeed() {
            var dummy = []
            for (var activity of this.$props.feed_data) {
                if (activity["target_type"] === "Contribution") {
                    dummy.push(activity)
                }
            }
            return dummy
        },
        getPurchaseFeed() {
            var dummy = []
            for (var activity of this.$props.feed_data) {
                if (activity["target_type"] === "Purchase" || activity["target_type"] === "Donation") {
                    dummy.push(activity)
                }
            }
            return dummy
        }
    },
    watch: {
    }
}
</script>

<style scoped>
@import "./stylesheets/_sidebar.scss";

</style>
