<template>
  <div class = "vue-app">
    <!-- <div v-if="this.feed_data"> -->
    <ActivityButton :varName="varName" />
    <div v-if="Object.keys(this.varName)[0] === 'user'">
      <ActivitySidebar :feed_data="this.feed_data" />
    </div>
    <div v-else>
      <OrgSidebar :feed_data="this.feed_data"/>
    </div>
  </div>
</template>

<script>

import ActivityButton from "activity.vue"
import ActivitySidebar from "activity-sidebar.vue"
import OrgSidebar from "org-sidebar.vue"
import {start, current, api_response} from "./entrypoints/sidebar.js"

export default {
    name: 'vue-app',
    data() {
        return {
          varName: JSON.parse(this.$attrs['data']),
          feed_data: [],
          userFeed: [],
          charityFeed: []
        }
    },
    components: {
      ActivityButton,
      ActivitySidebar,
      OrgSidebar
    },
    mounted() {
      // console.log("MOUNTED")
      // console.log("DATA: " + Object.keys(this.varName)[0]);
      console.log("DATA: " + this.varName);
      console.log("DATA: " + Object.keys(this.varName));
      console.log("DATA: " + Object.keys(this.varName)[0]);
      console.log("DATA: " + this.$attrs);
      console.log("custom domain: " + this.varName[Object.keys(this.varName)[0]]['custom_domain']);
      var customDomain = this.varName[Object.keys(this.varName)[0]]['custom_domain'];
      if(Object.keys(this.varName)[0] === "user") {
        var x = 'users/'+ this.varName['user']['slug'] + '/activity_feed.json?performer=others'
        console.log(x)
        this.$http.secured.get(x)
          .then(response => {
            console.log("RESPONSE")
            console.log(response.data)
            this.feed_data = response.data;
            })
          .finally(() => start.loading = false)
        api_response.feed(this.feed_data);
      }
      else {
        if (customDomain == "true") {
          var x =  '/activity_feed.json?performer=others';
        }
        else {
          var x = 'charities/'+ this.varName['organization']['slug'] + '/activity_feed.json';
        }
        console.log(this.varname)
        this.$http.secured.get(x)
          .then(response => {this.feed_data = response.data})
          .finally(() => start.loading = false)
        console.log("RESPONSE")
        api_response.feed(this.feed_data);
      }

    }
}

</script>

<style scoped="scss">
@import "./stylesheets/_sidebar.scss";


</style>
